import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_DOCUMENTS/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_DOCUMENTS/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_DOCUMENTS/LIST_FAILURE',
  CREATE_REQUEST: 'CARE_GUIDE_DOCUMENTS/CREATE_REQUEST',
  CREATE_SUCCESS: 'CARE_GUIDE_DOCUMENTS/CREATE_SUCCESS',
  CREATE_FAILURE: 'CARE_GUIDE_DOCUMENTS/CREATE_FAILURE',
  UPDATE_REQUEST: 'CARE_GUIDE_DOCUMENTS/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARE_GUIDE_DOCUMENTS/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARE_GUIDE_DOCUMENTS/UPDATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDE_DOCUMENTS/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDE_DOCUMENTS/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDE_DOCUMENTS/PATCH_FAILURE',
  FORM_DESTROY: 'CARE_GUIDE_DOCUMENTS/FORM_DESTROY',
  SHOW_FORM: 'CARE_GUIDE_DOCUMENTS/SHOW_FORM',
  HIDE_FORM: 'CARE_GUIDE_DOCUMENTS/HIDE_FORM',
  PREVIEW_DESTROY: 'CARE_GUIDE_DOCUMENTS/PREVIEW_DESTROY',
  SHOW_PREVIEW: 'CARE_GUIDE_DOCUMENTS/SHOW_PREVIEW',
  HIDE_PREVIEW: 'CARE_GUIDE_DOCUMENTS/HIDE_PREVIEW',
  UPLOAD_REQUEST: 'CARE_GUIDE_DOCUMENTS/UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'CARE_GUIDE_DOCUMENTS/UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'CARE_GUIDE_DOCUMENTS/UPLOAD_FAILURE',
  REMOVE_FILE_REQUEST: 'CARE_GUIDE_DOCUMENTS/REMOVE_FILE_REQUEST',
  REMOVE_FILE_SUCCESS: 'CARE_GUIDE_DOCUMENTS/REMOVE_FILE_SUCCESS',
  REMOVE_FILE_FAILURE: 'CARE_GUIDE_DOCUMENTS/REMOVE_FILE_FAILURE',
  CSV_DOWNLOAD_REQUEST: 'CARE_GUIDE_DOCUMENTS/CSV_DOWNLOAD_REQUEST',
  CSV_DOWNLOAD_SUCCESS: 'CARE_GUIDE_DOCUMENTS/CSV_DOWNLOAD_SUCCESS',
  CSV_DOWNLOAD_FAILURE: 'CARE_GUIDE_DOCUMENTS/CSV_DOWNLOAD_FAILURE',
  UPLOAD_URL_REQUEST: 'CARE_GUIDE_DOCUMENTS/UPLOAD_URL_REQUEST',
  UPLOAD_URL_SUCCESS: 'CARE_GUIDE_DOCUMENTS/UPLOAD_URL_SUCCESS',
  UPLOAD_URL_FAILURE: 'CARE_GUIDE_DOCUMENTS/UPLOAD_URL_FAILURE',
  DOWNLOAD_URL_REQUEST: 'CARE_GUIDE_DOCUMENTS/DOWNLOAD_URL_REQUEST',
  DOWNLOAD_URL_SUCCESS: 'CARE_GUIDE_DOCUMENTS/DOWNLOAD_URL_SUCCESS',
  DOWNLOAD_URL_FAILURE: 'CARE_GUIDE_DOCUMENTS/DOWNLOAD_URL_FAILURE',
};

export function careGuideDocumentListRequest(
  careGuideId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentListSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentListFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentUpdateRequest(careGuideId, id, data) {
  return {
    type: TYPES.UPDATE_REQUEST,
    careGuideId: careGuideId,
    id: id,
    data: data,
  };
}

export function careGuideDocumentUpdateSuccess(careGuideId, id, data) {
  return {
    type: TYPES.UPDATE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentUpdateFailure(careGuideId, error) {
  return {
    type: TYPES.UPDATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentPatchRequest(careGuideId, id, data) {
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
    data: data,
  };
}

export function careGuideDocumentPatchSuccess(careGuideId, id, data) {
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentPatchFailure(careGuideId, error) {
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentFormDestroy(formState = null) {
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function careGuideDocumentShowForm(id = null) {
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function careGuideDocumentHideForm() {
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function careGuideDocumentPreviewDestroy(previewState = null) {
  return {
    type: TYPES.PREVIEW_DESTROY,
    preview: previewState,
  };
}

export function careGuideDocumentShowPreview(id = null) {
  return {
    type: TYPES.SHOW_PREVIEW,
    id: id,
  };
}

export function careGuideDocumentHidePreview() {
  return {
    type: TYPES.HIDE_PREVIEW,
  };
}

export function careGuideDocumentCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
    data: data,
  };
}

export function careGuideDocumentCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentUploadRequest() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadRequest()`
  );
  return {
    type: TYPES.UPLOAD_REQUEST,
  };
}

export function careGuideDocumentUploadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadSuccess()`
  );
  return {
    type: TYPES.UPLOAD_SUCCESS,
  };
}

export function careGuideDocumentUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPLOAD_FAILURE,
    error: error,
  };
}

export function careGuideDocumenRemoveFileRequest() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumenRemoveFileRequest()`
  );
  return {
    type: TYPES.REMOVE_FILE_REQUEST,
  };
}

export function careGuideDocumentRemoveFileSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentRemoveFileSuccess()`
  );
  return {
    type: TYPES.REMOVE_FILE_SUCCESS,
  };
}

export function careGuideDocumentRemoveFileFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentRemoveFileFailure(%j)`,
    error
  );
  return {
    type: TYPES.REMOVE_FILE_FAILURE,
    error: error,
  };
}

export function careGuideDocumentsCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadRequest()`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentsCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadSuccess()`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideDocumentsCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadFailure()`
  );
  return {
    type: TYPES.CSV_DOWNLOAD_FAILURE,
  };
}

export function careGuideDocumentUploadURLRequest(extension, directory) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentUploadURLRequest(${extension}, ${directory})`
  );
  return {
    type: TYPES.UPLOAD_URL_REQUEST,
    extension: extension,
    directory: directory,
  };
}

export function careGuideDocumentUploadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentUploadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.UPLOAD_URL_SUCCESS,
    upload_url: data.upload_url,
    file_id: data.file_id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentUploadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentUploadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPLOAD_URL_FAILURE,
    error: error,
  };
}

export function careGuideDocumentDownloadURLRequest(fileKey, fileName) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentDownloadURLRequest(${fileKey}, ${fileName})`
  );
  return {
    type: TYPES.DOWNLOAD_URL_REQUEST,
    file_key: fileKey,
    file_name: fileName,
  };
}

export function careGuideDocumentDownloadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentDownloadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DOWNLOAD_URL_SUCCESS,
    download_url: data.download_url,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentDownloadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentDownloadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPLOAD_URL_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function createCareGuideDocument(
  careGuideId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] createCareGuideDocument(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideDocument(careGuideId, data);
    let success = false;

    // post care guide document success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide document success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentCreateSuccess(careGuideId, data));
      success = true;

      // post care guide document failure
    } else {
      Logger.log(
        'info',
        `POST API care guide document failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideDocumentCreateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideDocument(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] updateCareGuideDocument(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideDocument(careGuideId, id, data);
    let success = false;

    // put care guide document success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API care guide document success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentUpdateSuccess(careGuideId, data));
      success = true;

      // put care guide document failure
    } else {
      Logger.log(
        'info',
        `UPDATE API care guide document failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        careGuideDocumentUpdateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchCareGuideDocument(
  careGuideId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] patchCareGuideDocument(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideDocument(careGuideId, id, data);
    let success = false;

    // patch care guide document success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API care guide document success. Care Guide ID: ${careGuideId} ID: ${id}.`
      );
      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentPatchSuccess(careGuideId, id, data));
      success = true;

      // patch care guide document failure
    } else {
      Logger.log(
        'info',
        `Patch API care guide document failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideDocumentPatchFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function careGuideDocumentRemoveFile(path, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentRemoveFile(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumenRemoveFileRequest());

    // call API
    const response = await api.deleteFile(path);
    let success = false;

    // delete file success
    if (response.getIn(['data', 'success'])) {
      Logger.log('info', `Delete S3 file success.`);
      success = true;
      dispatch(careGuideDocumentRemoveFileSuccess());

      // delete file failure
    } else {
      Logger.log('info', `Delete S3 file failure.`);
      dispatch(careGuideDocumentRemoveFileFailure('Delete S3 error.'));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideDocuments(
  careGuideId = null,
  page = 1,
  limit = 15,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] loadCareGuideDocuments(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );
  return async function (dispatch) {
    dispatch(
      careGuideDocumentListRequest(careGuideId, page, limit, order, filter)
    );

    // call API
    const response = await api.getCareGuideDocuments(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide documents list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents list success. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_documents']),
        [schema.careGuideDocument]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentListSuccess(careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents list success [empty]. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(careGuideDocumentListSuccess(careGuideId, data));
      success = true;

      // get care guide documents list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide documents list failure. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        careGuideDocumentListFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function uploadFile(path, file, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] uploadFile(${path}, ###, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUploadRequest());

    // call API
    const response = await api.putFileUpload(path, file);
    let success = false;

    // put document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 document success.`);
      success = true;
      dispatch(careGuideDocumentUploadSuccess());

      // put document failure
    } else {
      Logger.log('info', `PUT S3 document failure.`);
      dispatch(careGuideDocumentUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideDocumentsCSV(
  partnershipName = null,
  careGuideId = null,
  payload,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] downloadCareGuideDocumentsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentsCSVDownloadRequest(order, filter));

    // call API
    const response = await api.postCareGuideDocumentsCSV(
      careGuideId,
      payload,
      order,
      filter
    );
    let success = false;

    // get care guide documents CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-${partnershipName}-care-guide-documents-${Format.date(
          now,
          'YYYY-MM-DDTHHmm'
        )}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideDocumentsCSVDownloadSuccess());
      success = true;

      // get care guide documents CSV failure
    } else {
      Logger.log('info', `Get API care guide documents CSV failure.`);
      dispatch(careGuideDocumentsCSVDownloadFailure());
    }
    // callback function
    cb(success);
  };
}

export function getCareGuideDocumentUploadURL(
  extension,
  directory,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] getCareGuideDocumentUploadURL(${extension}, ${directory})`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUploadURLRequest(extension, directory));

    // call API
    const response = await api.getFileUploadURL(extension, directory);
    let success = false;

    const careGuideDocumentUploadUrlSuccessData = {
      upload_url: null,
      file_id: null,
      bucket: null,
    };

    // get care guide document upload url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide document upload URL success.`);

      success = true;
      careGuideDocumentUploadUrlSuccessData.upload_url = response.getIn([
        'data',
        'upload_url',
      ]);
      careGuideDocumentUploadUrlSuccessData.file_key = response.getIn([
        'data',
        'file_key',
      ]);
      careGuideDocumentUploadUrlSuccessData.bucket = response.getIn([
        'data',
        'bucket',
      ]);

      dispatch(
        careGuideDocumentUploadURLSuccess(careGuideDocumentUploadUrlSuccessData)
      );

      // get care guide document upload url failure
    } else {
      Logger.log('info', `Get API care guide document upload URL failure.`);
      dispatch(
        careGuideDocumentUploadURLFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(
      success,
      careGuideDocumentUploadUrlSuccessData.upload_url,
      careGuideDocumentUploadUrlSuccessData.file_key,
      careGuideDocumentUploadUrlSuccessData.bucket
    );
  };
}

export function getCareGuideDocumentDownloadURL(
  fileKey,
  fileName,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] getCareGuideDocumentDownloadURL(${fileKey}, ${fileName})`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentDownloadURLRequest(fileKey, fileName));

    // call API
    const response = await api.getFileDownloadURL(fileKey, fileName);
    let success = false;

    const careGuideDocumentDownloadUrlSuccessData = {
      download_url: null,
      file_key: null,
    };

    // get care guide document download url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide document download URL success.`);

      success = true;
      careGuideDocumentDownloadUrlSuccessData.download_url = response.getIn([
        'data',
        'download_url',
      ]);
      careGuideDocumentDownloadUrlSuccessData.file_key = response.getIn([
        'data',
        'file_key',
      ]);

      dispatch(
        careGuideDocumentDownloadURLSuccess(
          careGuideDocumentDownloadUrlSuccessData
        )
      );

      // get care guide document download url failure
    } else {
      Logger.log('info', `Get API care guide document download URL failure.`);
      dispatch(
        careGuideDocumentDownloadURLFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(
      success,
      careGuideDocumentDownloadUrlSuccessData.download_url,
      careGuideDocumentDownloadUrlSuccessData.file_key
    );
  };
}

Logger.log('silly', `state.careGuideDocuments.actions loaded.`);

import { connect } from 'react-redux';

import MemberProfileTags from '../components/MemberProfileTags';
import { loadAdminPartnerClassPartnershipAdmin } from '../../../../../state/modules/adminPartnerClassesAdmin/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, cb) => {
      dispatch(loadAdminPartnerClassPartnershipAdmin(partnershipId, cb));
    },
  };
};

const MemberProfileTagsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProfileTags);

export default MemberProfileTagsContainer;

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { pathTo } from '../../../Routes';
import { Typography, List, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PlusOutlined } from '@ant-design/icons';
import DashboardAdminRecommendedUtilityFormModal from '../containers/DashboardAdminRecommendedUtilityFormModalContainer';
import QueryString from '../../../../../lib/QueryString';
import notification from '../../../elements/lib/NotificationWrapper';

const { Text } = Typography;

const DashboardAdminRecommendedUtilities = ({
  list,
  isLoading,
  total,
  load,
  partnershipId,
  page_recommended_utilities,
  page_resources,
  limit,
  order,
  showForm,
  history,
  component,
  download,
  patch,
  remove,
  ...props
}) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  useEffect(() => {
    load(partnershipId, page_recommended_utilities, limit, order);
  }, [partnershipId, page_recommended_utilities, limit, order, load]);

  const paginationList = {
    onChange: (newPage, pageSize) => {
      let path = props.location.pathname;
      const params = {};

      path = pathTo(component, {
        page_recommended_utilities: newPage,
        page_resources: page_resources,
      });

      history.push(QueryString.append(path, params));
    },
    current: page_recommended_utilities,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
  };

  const downloadHandler = (item) => {
    const path = `${item?.file_directory}/${item?.file_name_bucket}.${item?.file_extension}`;
    const fileName = `${item?.file_name}`;

    download(path, fileName, (success, downloadUrl) => {
      if (success) {
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        notification.success(
          getI18n().t('recommended_utilities_download_success', {
            fileName: fileName,
          })
        );
      }
    });
  };

  const handleDelete = (item) => {
    if (item.website) {
      remove(partnershipId, item.id, (success) => {
        if (success) {
          load(partnershipId, page_recommended_utilities, limit, order);
          notification.success(
            getI18n().t('recommended_utilities_delete_success')
          );
        } else {
          notification.error(
            getI18n().t('recommended_utilities_message_form_failure_delete')
          );
        }
      });
    } else {
      patch(partnershipId, item.id, { status: 3 }, (success) => {
        if (success) {
          load(partnershipId, page_recommended_utilities, limit, order);
          notification.success(
            getI18n().t('recommended_utilities_delete_success')
          );
        } else {
          notification.error(
            getI18n().t('recommended_utilities_message_form_failure_delete')
          );
        }
      });
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div style={{ paddingLeft: '16px', width: '100%' }}>
            <Text type='secondary'>
              {t('recommended_utilities_title_list')}
            </Text>
            <List
              className='recommended-utilities-list-admin'
              bordered={false}
              dataSource={list}
              pagination={
                page_recommended_utilities === 1 && total <= limit
                  ? false
                  : paginationList
              }
              renderItem={(item) => (
                <List.Item>
                  <div className='recommended-utility-card'>
                    {item.website ? (
                      <Text underline>
                        <a
                          style={{
                            color: '#676767',
                            fontSize: '16px',
                          }}
                          href={withHttp(item.website)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {item.title}
                        </a>
                      </Text>
                    ) : (
                      <Text
                        underline
                        style={{
                          color: '#676767',
                          fontSize: '16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => downloadHandler(item)}
                      >
                        {item.title}
                      </Text>
                    )}

                    <Space>
                      <FontAwesomeIcon
                        onClick={() => showForm(item.id)}
                        className='cursor-pointer'
                        icon={faPenToSquare}
                        style={{ fontSize: '16px', color: '#5399DD' }}
                      />

                      <FontAwesomeIcon
                        onClick={() => handleDelete(item)}
                        className='cursor-pointer'
                        icon={faTrashCan}
                        style={{ fontSize: '16px', color: 'red' }}
                      />
                    </Space>
                  </div>
                </List.Item>
              )}
            >
              <Button
                key='back'
                disabled={isLoading}
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => showForm(null)}
                style={{ marginTop: '10px' }}
              >
                {t('recommended_utilities_add_content')}
              </Button>
            </List>
          </div>
          <DashboardAdminRecommendedUtilityFormModal
            careGuideId={partnershipId}
            page={page_recommended_utilities}
            limit={limit}
            order={order}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(DashboardAdminRecommendedUtilities);

import {
  Modal,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  Upload,
  Space,
} from 'antd';
import { Translation, getI18n } from 'react-i18next';
import {
  UploadOutlined,
  FileOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import PinButton from '../../../elements/components/PinButton';
import React, { useState, useEffect } from 'react';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import Logger from '../../../../../lib/Logger';
import notification from '../../../elements/lib/NotificationWrapper';
import { documentCheck } from '../../../elements/components/Icons';

const { Title } = Typography;

const layout = {
  main: {
    labelCol: { span: 24 },
  },
};

const confirmTexts = {
  title: getI18n().t('care_guide_document_confirm_modal_title'),
  description: getI18n().t('care_guide_document_confirm_modal_description'),
  leaveText: getI18n().t('care_guide_document_confirm_modal_leave'),
  saveText: getI18n().t('care_guide_document_confirm_modal_return'),
};

const CareGuideDocumentFormModal = ({
  careGuideId,
  careGuideDocumentId,
  page,
  limit,
  filter,
  order,
  remove,
  isSubmitting,
  data,
  errors,
  ...props
}) => {
  const [form] = Form.useForm();

  const [isPinned, setIsPinned] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isChangeValue, setIsChangeValue] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isBeforeUpload, setIsBeforeUpload] = useState(false);

  // upload criteria
  const fileTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'text/csv',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

  const onBeforeUpload = (file) => {
    const isSupportedFileType = fileTypes.includes(file.type);

    if (!isSupportedFileType) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t(
                'care_guide_document_message_form_failure_file_type'
              )}
            </Space>,
          ],
        },
      ]);

      setUploadedFile([]);
      return Upload.LIST_IGNORE;
    }

    setUploadedFile([file]);
    setIsBeforeUpload(true);
    return false;
  };

  const handlePayload = (values, existingPayload = {}) => {
    const payload = { ...existingPayload };

    for (const key in values) {
      if (key === 'doc-file') {
        continue;
      }

      const value = key === 'is_pinned' ? isPinned : values[key];

      if (value !== '' && value !== null) {
        payload[key] = value;
      } else {
        delete payload[key];
      }
    }

    return payload;
  };

  const updateDocument = async (values, payload) => {
    props.update(careGuideId, careGuideDocumentId, payload, (success) => {
      if (success) {
        clearForm();
        notification.success(
          getI18n().t('care_guide_document_message_data_success')
        );
        props.load(careGuideId, page, limit, order, filter);
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_update')
        );
      }
    });
  };

  const createDocument = async (values, payload) => {
    props.create(careGuideId, payload, (success) => {
      if (success) {
        notification.success(
          getI18n().t('care_guide_document_message_form_success')
        );
        clearForm();
        props.load(careGuideId, page, limit, order, filter);
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_create')
        );
      }
    });
  };

  const handleFileUpload = async (values) => {
    const handleUploadSuccess = (uploadSuccess, file_id, bucket) => {
      if (uploadSuccess) {
        const directory = 'documents/';
        if (file_id) {
          file_id = file_id.replace(directory, '');

          const matches = reFileKey.exec(file_id);
          const payload = {
            file_directory: directory.slice(0, -1),
            file_name: matches[2],
            file_extension: matches[3],
            file_bucket: bucket,
            size_bytes: values['doc-file'].size,
            user_file_name: values['user_file_name'],
          };

          const updatedPayload = handlePayload(values, payload);
          if (careGuideDocumentId) {
            const path = `${data.file_directory}/${data.file_name}.${data.file_extension}`;
            remove(path, (successDestroy) => {
              if (successDestroy) {
                updateDocument(values, updatedPayload);
              } else {
                notification.error(
                  getI18n().t(
                    'care_guide_document_message_form_failure_delete_aws'
                  )
                );
              }
            });
          } else {
            createDocument(values, updatedPayload);
          }
        }
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_uploading')
        );
      }
    };

    props.getUploadUrl(
      values['doc-file'].type.split('/').pop(),
      'documents',
      async (success, upload_url, file_id, bucket) => {
        if (success) {
          props.upload(upload_url, values['doc-file'], (uploadSuccess) =>
            handleUploadSuccess(uploadSuccess, file_id, bucket)
          );
        }
      }
    );
  };

  const submitData = async (values) => {
    Logger.log('debug', `CareGuidedDocumentModalForm.submitData(###)`);

    if (values['doc-file'] === 'preloaded') {
      const payload = handlePayload(values, data);
      updateDocument(values, payload);
    } else {
      handleFileUpload(values);
    }
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'removed') {
      setUploadedFile([]);
      form.setFieldsValue({
        'doc-file': null,
      });
    } else {
      setUploadedFile([info.file]);
      form.setFieldsValue({
        'doc-file': info.file,
      });
    }
  };

  const onConfirmModal = () => {
    props.hideForm();
    setConfirmationModalOpen(true);
  };

  const onCancelConfirmModal = () => {
    setConfirmationModalOpen(false);
    props.showForm(careGuideDocumentId);
  };

  const onLeaveConfirmModal = () => {
    setConfirmationModalOpen(false);
    clearForm();
  };

  const onSaveConfirmModal = () => {
    setConfirmationModalOpen(false);
    props.showForm(careGuideDocumentId);
  };

  const handlePinClick = () => {
    setIsPinned(!isPinned);
    setIsChangeValue(true);
    form.setFields([
      {
        name: ['is_pinned'],
        value: !isPinned,
      },
    ]);
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    setIsChangeValue(false);
    setIsPinned(false);
    setIsBeforeUpload(false);
    setUploadedFile([]);
    form.setFieldsValue({
      is_pinned: false,
      user_file_name: '',
      description: '',
      'doc-file': '',
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuidedDocumentModalForm.handleFinish(###)`);
    if (!isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuidedDocumentModalForm.handleFinishFailed(###)`);
    notification.error(getI18n().t('care_guide_document_message_form_failure'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error notification when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    setIsChangeValue(true);
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  useEffect(() => {
    if (careGuideDocumentId) {
      form.setFieldsValue({
        user_file_name: data['user_file_name'],
        description: data['description'],
        'doc-file': 'preloaded',
      });

      const defaultFile = [
        {
          uid: '3463631', // a unique identifier
          name: `${data['user_file_name']}.${data['file_extension']}`,
          status: 'done',
        },
      ];
      setUploadedFile(defaultFile);

      setIsPinned(data['is_pinned']);
    }
  }, [data, form, careGuideDocumentId]);

  const uploadIcon = () => {
    return isBeforeUpload ? documentCheck() : <FileOutlined />;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Row justify='space-between'>
                <Col>
                  <Title level={3} className='doc-title'>
                    {careGuideDocumentId
                      ? t('care_guide_documents_form_modal_header_title_edit')
                      : t('care_guide_documents_form_modal_header_title_new')}
                  </Title>
                </Col>
                <Col>
                  <PinButton isPinned={isPinned} setIsPinned={handlePinClick} />
                </Col>
              </Row>
            }
            closable={false}
            className='note-modal-export'
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={isChangeValue ? onConfirmModal : clearForm}
            footer={[
              <Button
                key='back'
                onClick={isChangeValue ? onConfirmModal : clearForm}
              >
                {t('care_guide_documents_form_modal_cancel')}
              </Button>,
              <Button
                key='submit'
                type='primary'
                className='btn-save'
                loading={isSubmitting}
                onClick={onClickOK}
              >
                {t('care_guide_documents_form_modal_save')}
              </Button>,
            ]}
          >
            <Form
              form={form}
              name='care_guide_document_form'
              {...layout.main}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger='onSubmit'
              className='form-doc-modal'
            >
              <Form.Item name={'is_pinned'} style={{ display: 'none' }}>
                <Input type='hidden' />
              </Form.Item>
              <Form.Item
                name='user_file_name'
                label={t('care_guide_documents_form_modal_title_field_label')}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'care_guide_documents_form_modal_title_field_placeholder'
                  )}
                />
              </Form.Item>

              <Form.Item
                name='description'
                label={t(
                  'care_guide_documents_form_modal_description_field_label'
                )}
              >
                <Input.TextArea
                  showCount
                  rows={4}
                  placeholder={t(
                    'care_guide_documents_form_modal_description_field_placeholder'
                  )}
                  maxLength={100}
                />
              </Form.Item>

              <Form.Item
                name='doc-file'
                label={t('care_guide_documents_form_modal_upload_field_label')}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                ]}
              >
                <Upload
                  className={isBeforeUpload ? 'doc-upload' : ''}
                  iconRender={uploadIcon}
                  onChange={(info) => handleFileChange(info)}
                  fileList={uploadedFile}
                  listType={'picture'}
                  beforeUpload={onBeforeUpload}
                  multiple={false}
                >
                  <Button icon={<UploadOutlined />}>
                    {t('care_guide_documents_form_modal_upload_button')}
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Modal>
          <ConfirmationModal
            texts={confirmTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={onLeaveConfirmModal}
            onSaveConfirmation={onSaveConfirmModal}
            onCancel={onCancelConfirmModal}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </>
      )}
    </Translation>
  );
};

export default CareGuideDocumentFormModal;

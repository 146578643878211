import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RECOMMENDED_UTILITIES/LIST_REQUEST',
  LIST_SUCCESS: 'RECOMMENDED_UTILITIES/LIST_SUCCESS',
  LIST_FAILURE: 'RECOMMENDED_UTILITIES/LIST_FAILURE',
  DOWNLOAD_URL_REQUEST: 'RECOMMENDED_UTILITIES/DOWNLOAD_URL_REQUEST',
  DOWNLOAD_URL_SUCCESS: 'RECOMMENDED_UTILITIES/DOWNLOAD_URL_SUCCESS',
  DOWNLOAD_URL_FAILURE: 'RECOMMENDED_UTILITIES/DOWNLOAD_URL_FAILURE',
};

export function recommendedUtilityListRequest(
  partnershipId,
  careGuideId,
  page,
  limit,
  order
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListRequest(${careGuideId}, ${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function recommendedUtilityListSuccess(
  partnershipId,
  careGuideId,
  data
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListSuccess(${careGuideId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityListFailure(
  partnershipId,
  careGuideId,
  error
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListFailure(${careGuideId}, ${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityDownloadURLRequest(fileKey, fileName) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadURLRequest(${fileKey}, ${fileName})`
  );
  return {
    type: TYPES.DOWNLOAD_URL_REQUEST,
    file_key: fileKey,
    file_name: fileName,
  };
}

export function recommendedUtilityDownloadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DOWNLOAD_URL_SUCCESS,
    download_url: data.download_url,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityDownloadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOWNLOAD_URL_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadRecommendedUtilities(
  partnershipId = null,
  careGuideId = null,
  page = 1,
  limit = 15,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] loadRecommendedUtilities(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order})`
  );
  return async function (dispatch) {
    dispatch(
      recommendedUtilityListRequest(
        partnershipId,
        careGuideId,
        page,
        limit,
        order
      )
    );

    // call API
    const response = await api.getRecommendedUtilities(
      partnershipId,
      careGuideId,
      page,
      limit,
      order
    );
    let success = false;

    // get recommended utilities list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtility]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityListSuccess(partnershipId, careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success [empty].  Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(recommendedUtilityListSuccess(partnershipId, careGuideId, data));
      success = true;

      // get recommended utilities list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities list failure.  Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityListFailure(
          partnershipId,
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function getRecommendedUtilityDownloadURL(
  fileKey,
  fileName,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] getRecomendedUtilityDownloadURL(${fileKey}, ${fileName})`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDownloadURLRequest(fileKey, fileName));

    // call API
    const response = await api.getFileDownloadURL(fileKey, fileName);
    let success = false;

    const recommendedUtilityDownloadUrlSuccessData = {
      download_url: null,
      file_key: null,
    };

    // get recommended utility download url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API recommended utility download URL success.`);

      success = true;
      recommendedUtilityDownloadUrlSuccessData.download_url = response.getIn([
        'data',
        'download_url',
      ]);
      recommendedUtilityDownloadUrlSuccessData.file_key = response.getIn([
        'data',
        'file_key',
      ]);

      dispatch(
        recommendedUtilityDownloadURLSuccess(
          recommendedUtilityDownloadUrlSuccessData
        )
      );

      // get recommended utility download url failure
    } else {
      Logger.log('info', `Get API recommended utility download URL failure.`);
      dispatch(
        recommendedUtilityDownloadURLFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(
      success,
      recommendedUtilityDownloadUrlSuccessData.download_url,
      recommendedUtilityDownloadUrlSuccessData.file_key
    );
  };
}

Logger.log('silly', `state.recommendedUtilities.actions loaded.`);

import { Modal, Typography, Button } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import notification from '../../../elements/lib/NotificationWrapper';

import '../styles/CareGuideDocumentPreviewModal.less';

const { Title } = Typography;

const CareGuideDocumentPreviewModal = ({
  careGuideDocumentId,
  data,
  ...props
}) => {
  const clearModal = () => {
    props.hidePreview();
    props.previewDestroy();
  };

  const editHandler = () => {
    clearModal();
    props.showForm(careGuideDocumentId);
  };

  const downloadHandler = () => {
    const path = `${data?.file_directory}/${data?.file_name}.${data?.file_extension}`;
    const fileName = `${data?.user_file_name}.${data?.file_extension}`;
    props.download(path, fileName, (success, downloadUrl) => {
      if (success) {
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        props.hidePreview();

        notification.success(
          getI18n().t('care_guide_document_preview_modal_download_success', {
            fileName: fileName,
          })
        );
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <Modal
          title={
            <Title level={3} className='doc-title'>
              {data?.user_file_name}
            </Title>
          }
          closable={false}
          className='note-modal-export'
          centered
          onCancel={clearModal}
          visible={props.visible}
          footer={
            <div className='doc-footer'>
              <Button key='back' className='btn-edit' onClick={editHandler}>
                <FiEdit />
                <span>{t('care_guide_documents_edit')}</span>
              </Button>
            </div>
          }
        >
          {data?.description && (
            <section>
              <h3 style={{ fontWeight: '600' }}>
                {t('care_guide_documents_preview_modal_description')}
              </h3>
              <p>{data?.description}</p>
            </section>
          )}

          <section>
            <h3 style={{ fontWeight: '600' }}>
              {t('care_guide_documents_preview_modal_document')}
            </h3>
            <button className='doc-file' onClick={downloadHandler}>
              <div style={{ position: 'relative' }}>
                <FileOutlined className='doc-file-icon' />
              </div>
              <p>
                {data?.user_file_name}.{data?.file_extension}
              </p>
            </button>
          </section>
        </Modal>
      )}
    </Translation>
  );
};

export default CareGuideDocumentPreviewModal;

import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import MemberProfile from '../components/MemberProfile';

import {
  loadCareGuideAdmin,
  updateCareGuideAdmin,
} from '../../../../../state/modules/careGuidesAdmin/actions';
import { List } from 'immutable';

const inputs = List([
  'care_partner_tags',
  'account',
  'partnership',
  'subscription_status',
]);

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const adminPartnerClass = state.adminPartnerClassesAdmin.getIn([
    'adminPartnerClassesAdmin',
    partnershipId,
  ]);
  let nameClasses = [];

  const errors = {};
  const member = state.entities.getIn([
    'careGuidesAdmin',
    ownProps.careGuideId,
  ]);

  for (const val of inputs.values()) {
    errors[val] = state.careGuidesAdmin.getIn(['form', 'errors', val]);
  }

  const data = {
    ...member,
    careguide_profile: {
      id: member?.id,
      first_name: member?.first_name,
      last_name: member?.last_name,
      status: member?.account.subscription_status,
      avatar: member?.image,
      relationship: member?.relationship,
      postal_code: member?.postal_code,
      created_at: member?.account?.created_at,
    },
    owner_profile: {
      ...state.entities.getIn(['userProfiles', member?.owner])?.profile,
      status: member?.account.subscription_status,
    },
  };

  if (data['care_partner_tags'] === '') {
    data['care_partner_tags'] = [];
  }

  Object.keys(data).forEach((x) => {
    if (['account'].includes(x)) {
      data['subscription_status'] = data[x]['subscription_status'];
    }
  });

  const adminPartnerClassesAdmin = adminPartnerClass
    ? adminPartnerClass
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['adminPartnerClassesAdmin', x]),
              schema.adminPartnerClassAdmin,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
        .map((x) => {
          nameClasses.push(x.name);
          return {
            ...x,
          };
        })
    : [];

  return {
    data,
    errors,
    isLoading: state.careGuides.get('isLoading'),
    isSubmitting: state.careGuidesAdmin.getIn(['form', 'isSubmitting']),
    options: adminPartnerClassesAdmin,
    isLoadingTags: state.adminPartnerClassesAdmin.get('isLoading'),
    nameClasses: nameClasses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partershipId, careguideId, cb) => {
      dispatch(loadCareGuideAdmin(partershipId, careguideId, cb));
    },
    update: (partershipId, careguideId, data, cb) => {
      dispatch(updateCareGuideAdmin(partershipId, careguideId, data, cb));
    },
  };
};

const MemberProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProfile);

export default MemberProfileContainer;

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Typography, List, Empty } from 'antd';
import notification from '../../../elements/lib/NotificationWrapper';

const { Text } = Typography;

const DashboardRecommendedUtilities = ({
  list,
  isLoading,
  total,
  load,
  careGuideId,
  partnershipId,
  page_recommended_utilities,
  setPageRecommendedUtilities,
  page_resources,
  limit,
  order,
  history,
  component,
  download,
  ...props
}) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  useEffect(() => {
    load(partnershipId, careGuideId, page_recommended_utilities, limit, order);
  }, [
    partnershipId,
    careGuideId,
    page_recommended_utilities,
    limit,
    order,
    load,
  ]);

  const paginationList = {
    onChange: (newPage, pageSize) => {
      setPageRecommendedUtilities(newPage);
    },
    current: page_recommended_utilities,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
  };

  const downloadHandler = (item) => {
    const path = `${item?.file_directory}/${item?.file_name_bucket}.${item?.file_extension}`;
    const fileName = `${item?.file_name}`;

    download(path, fileName, (success, downloadUrl) => {
      if (success) {
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        notification.success(
          getI18n().t('recommended_utilities_download_success', {
            fileName: fileName,
          })
        );
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <div style={{ width: '100%' }}>
              <Text type='secondary'>
                {t('recommended_utilities_title_list')}
              </Text>
              <List
                className='recommended-utilities-list'
                bordered={false}
                dataSource={list}
                pagination={
                  page_recommended_utilities === 1 && total <= limit
                    ? false
                    : paginationList
                }
                renderItem={(item) => (
                  <List.Item>
                    {item.website ? (
                      <Text underline>
                        <a
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                          href={withHttp(item.website)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {item.title}
                        </a>
                      </Text>
                    ) : (
                      <Text
                        className='cursor-pointer'
                        underline
                        style={{
                          color: '#4E77B8',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                        onClick={() => downloadHandler(item)}
                      >
                        {item.title}
                      </Text>
                    )}
                  </List.Item>
                )}
              ></List>
            </div>
          ) : (
            <div
              style={{
                height: '260px',
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Empty
                description={<Text>{t('recommended_utilities_empty')}</Text>}
              />
            </div>
          )}
        </>
      )}
    </Translation>
  );
};

export default withRouter(DashboardRecommendedUtilities);

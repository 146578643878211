import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/LIST_REQUEST',
  LIST_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/LIST_SUCCESS',
  LIST_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/LIST_FAILURE',
  CREATE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_REQUEST',
  CREATE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_SUCCESS',
  CREATE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_FAILURE',
  UPDATE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_FAILURE',
  FORM_DESTROY: 'RECOMMENDED_UTILITIES_ADMIN/FORM_DESTROY',
  SHOW_FORM: 'RECOMMENDED_UTILITIES_ADMIN/SHOW_FORM',
  HIDE_FORM: 'RECOMMENDED_UTILITIES_ADMIN/HIDE_FORM',
  PATCH_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_REQUEST',
  PATCH_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_SUCCESS',
  PATCH_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_FAILURE',
  DELETE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_REQUEST',
  DELETE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_SUCCESS',
  DELETE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_FAILURE',
  UPLOAD_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_FAILURE',
  REMOVE_FILE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/REMOVE_FILE_REQUEST',
  REMOVE_FILE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/REMOVE_FILE_SUCCESS',
  REMOVE_FILE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/REMOVE_FILE_FAILURE',
  UPLOAD_URL_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_URL_REQUEST',
  UPLOAD_URL_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_URL_SUCCESS',
  UPLOAD_URL_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/UPLOAD_URL_FAILURE',
  DOWNLOAD_URL_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/DOWNLOAD_URL_REQUEST',
  DOWNLOAD_URL_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/DOWNLOAD_URL_SUCCESS',
  DOWNLOAD_URL_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/DOWNLOAD_URL_FAILURE',
};

export function recommendedUtilityAdminListRequest(
  partnershipId,
  page,
  limit,
  order
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListRequest(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function recommendedUtilityAdminListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminUpdateRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityAdminUpdateSuccess(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateSuccess(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminUpdateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminFormDestroy()`
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function recommendedUtilityAdminShowForm(id = null) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminShowForm()`
  );
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function recommendedUtilityAdminHideForm() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminHideForm()`
  );
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function recommendedUtilityAdminCreateRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    partnershipId: partnershipId,
    data: data,
  };
}

export function recommendedUtilityAdminCreateSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminCreateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminUploadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadRequest()`
  );
  return {
    type: TYPES.UPLOAD_REQUEST,
  };
}

export function recommendedUtilityAdminUploadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadSuccess()`
  );
  return {
    type: TYPES.UPLOAD_SUCCESS,
  };
}

export function recommendedUtilityAdminUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPLOAD_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminRemoveFileRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminRemoveFileRequest()`
  );
  return {
    type: TYPES.REMOVE_FILE_REQUEST,
  };
}

export function recommendedUtilityAdminRemoveFileSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminRemoveFileSuccess()`
  );
  return {
    type: TYPES.REMOVE_FILE_SUCCESS,
  };
}

export function recommendedUtilityAdminRemoveFileFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminRemoveFileFailure(%j)`,
    error
  );
  return {
    type: TYPES.REMOVE_FILE_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminPatchRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminPatchRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityAdminPatchSuccess(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminPatchSuccess(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminRemoveFileFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminDeleteRequest(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteRequest(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityAdminDeleteSuccess(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteSuccess(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityAdminDeleteFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminUploadURLRequest(extension, directory) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadURLRequest(${extension}, ${directory})`
  );
  return {
    type: TYPES.UPLOAD_URL_REQUEST,
    extension: extension,
    directory: directory,
  };
}

export function recommendedUtilityAdminUploadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.UPLOAD_URL_SUCCESS,
    upload_url: data.upload_url,
    file_id: data.file_id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminUploadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPLOAD_URL_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminDownloadURLRequest(fileKey, fileName) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadURLRequest(${fileKey}, ${fileName})`
  );
  return {
    type: TYPES.DOWNLOAD_URL_REQUEST,
    file_key: fileKey,
    file_name: fileName,
  };
}

export function recommendedUtilityAdminDownloadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DOWNLOAD_URL_SUCCESS,
    download_url: data.download_url,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminDownloadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOWNLOAD_URL_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function createRecommendedUtilityAdmin(
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] createRecommendedUtilityAdmin(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminCreateRequest(partnershipId, data));

    // call API
    const response = await api.postRecommendedUtilityAdmin(partnershipId, data);
    let success = false;

    // post recommended utility success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API recommended utility success. Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminCreateSuccess(partnershipId, data));
      success = true;

      // post recommended utility failure
    } else {
      Logger.log(
        'info',
        `POST API recommended utility failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityAdminCreateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateRecommendedUtilityAdmin(
  partnershipId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] updateRecommendedUtilityAdmin(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminUpdateRequest(partnershipId, id, data));

    // call API
    const response = await api.putRecommendedUtilityAdmin(
      partnershipId,
      id,
      data
    );
    let success = false;

    // put recommended utility success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API recommended utility success. Partnership ID: ${partnershipId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminUpdateSuccess(partnershipId, data));
      success = true;

      // put recommended utility failure
    } else {
      Logger.log(
        'info',
        `UPDATE API recommended utility failure. Partnership ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        recommendedUtilityAdminUpdateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function recommendedUtilityAdminRemoveFile(path, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminRemoveFile(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminRemoveFileRequest());

    // call API
    const response = await api.deleteFile(path);
    let success = false;

    // put file success
    if (response.getIn(['data', 'success'])) {
      Logger.log('info', `Delete S3 file success.`);
      success = true;
      dispatch(recommendedUtilityAdminRemoveFileSuccess());

      // put file failure
    } else {
      Logger.log('info', `Delete S3 file failure.`);
      dispatch(recommendedUtilityAdminRemoveFileFailure('Delete S3 error.'));
    }

    // callback function
    cb(success);
  };
}

export function loadRecommendedUtilitiesAdmin(
  partnershipId = null,
  page = 1,
  limit = 15,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] loadRecommendedUtilities(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return async function (dispatch) {
    dispatch(
      recommendedUtilityAdminListRequest(partnershipId, page, limit, order)
    );

    // call API
    const response = await api.getRecommendedUtilitiesAdmin(
      partnershipId,
      page,
      limit,
      order
    );
    let success = false;

    // get recommended utilities admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities admin list success. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities admin list success [empty]. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(recommendedUtilityAdminListSuccess(partnershipId, data));
      success = true;

      // get recommended utilities admin list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities admin list failure. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityAdminListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function uploadFile(path, file, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] uploadFile(${path}, ###, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminUploadRequest());

    // call API
    const response = await api.putFileUpload(path, file);
    let success = false;

    // put document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 document success.`);
      success = true;
      dispatch(recommendedUtilityAdminUploadSuccess());

      // put document failure
    } else {
      Logger.log('info', `PUT S3 document failure.`);
      dispatch(recommendedUtilityAdminUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function patchRecommendedUtilityAdmin(
  partnershipId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] patchRecommendedUtilityAdmin(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminPatchRequest(partnershipId, id, data));

    // call API
    const response = await api.patchRecommendedUtilityAdmin(
      partnershipId,
      id,
      data
    );
    let success = false;

    // patch recommended utility admin success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API recommended utility admin success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminPatchSuccess(partnershipId, id, data));
      success = true;

      // patch recommended utility admin failure
    } else {
      Logger.log(
        'info',
        `Patch API recommended utility admin failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityAdminPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteRecommendedUtilityAdmin(
  partnershipId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] deleteRecommendedUtilityAdmin(${partnershipId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminDeleteRequest(partnershipId, id));

    // call API
    const response = await api.deleteRecommendedUtilityAdmin(partnershipId, id);
    let success = false;

    // delete recommended utility admin success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Delete API recommended utility admin success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      dispatch(recommendedUtilityAdminDeleteSuccess(partnershipId, id));
      success = true;

      // get recommended utility admin failure
    } else {
      Logger.log(
        'info',
        `Delete API recommended utility admin failure. Partnership ID: ${partnershipId}  ID: ${id}.`
      );
      dispatch(
        recommendedUtilityAdminDeleteFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function getRecommendedUtilityAdminUploadURL(
  extension,
  directory,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] getRecommendedUtilityAdminUploadURL(${extension}, ${directory})`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminUploadURLRequest(extension, directory));

    // call API
    const response = await api.getFileUploadURL(extension, directory);
    let success = false;

    const recommnededUtilityUploadUrlSuccessData = {
      upload_url: null,
      file_id: null,
      bucket: null,
    };

    // get recommended utility upload url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API recommended utility upload URL success.`);

      success = true;
      recommnededUtilityUploadUrlSuccessData.upload_url = response.getIn([
        'data',
        'upload_url',
      ]);
      recommnededUtilityUploadUrlSuccessData.file_key = response.getIn([
        'data',
        'file_key',
      ]);
      recommnededUtilityUploadUrlSuccessData.bucket = response.getIn([
        'data',
        'bucket',
      ]);

      dispatch(
        recommendedUtilityAdminUploadURLSuccess(
          recommnededUtilityUploadUrlSuccessData
        )
      );

      // get recommended utility upload url failure
    } else {
      Logger.log('info', `Get API recommended utility upload URL failure.`);
      dispatch(
        recommendedUtilityAdminUploadURLFailure(
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(
      success,
      recommnededUtilityUploadUrlSuccessData.upload_url,
      recommnededUtilityUploadUrlSuccessData.file_key,
      recommnededUtilityUploadUrlSuccessData.bucket
    );
  };
}

export function getRecommendedUtilityAdminDownloadURL(
  fileKey,
  fileName,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] getRecomendedUtilityAdminDownloadURL(${fileKey}, ${fileName})`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminDownloadURLRequest(fileKey, fileName));

    // call API
    const response = await api.getFileDownloadURL(fileKey, fileName);
    let success = false;

    const recommendedUtilityAdminDownloadUrlSuccessData = {
      download_url: null,
      file_key: null,
    };

    // get recommended utility download url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API recommended utility download URL success.`);

      success = true;
      recommendedUtilityAdminDownloadUrlSuccessData.download_url =
        response.getIn(['data', 'download_url']);
      recommendedUtilityAdminDownloadUrlSuccessData.file_key = response.getIn([
        'data',
        'file_key',
      ]);

      dispatch(
        recommendedUtilityAdminDownloadURLSuccess(
          recommendedUtilityAdminDownloadUrlSuccessData
        )
      );

      // get recommended utility download url failure
    } else {
      Logger.log('info', `Get API recommended utility download URL failure.`);
      dispatch(
        recommendedUtilityAdminDownloadURLFailure(
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(
      success,
      recommendedUtilityAdminDownloadUrlSuccessData.download_url,
      recommendedUtilityAdminDownloadUrlSuccessData.file_key
    );
  };
}

Logger.log('silly', `state.recommendedUtilitiesAdmin.actions loaded.`);

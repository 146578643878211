import React, { useState, useEffect } from 'react';
import {
  Modal,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  Upload,
  Space,
  Radio,
} from 'antd';
import { Translation, getI18n } from 'react-i18next';
import {
  UploadOutlined,
  FileOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import Logger from '../../../../../lib/Logger';
import notification from '../../../elements/lib/NotificationWrapper';
import { documentCheck } from '../../../elements/components/Icons';

const { Title, Text } = Typography;

const layout = {
  main: {
    labelCol: { span: 24 },
  },
};

const DashboardAdminRecommendedUtilityFormModal = ({
  partnershipId,
  recommendedUtilityId,
  visible,
  isLoading,
  page,
  limit,
  order,
  destroy,
  isSubmitting,
  data,
  errors,
  create,
  load,
  update,
  ...props
}) => {
  const [form] = Form.useForm();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [isBeforeUpload, setIsBeforeUpload] = useState(false);
  const [optionType, setOptionType] = useState(1);

  // upload criteria
  const fileTypes = ['application/pdf'];
  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

  const onBeforeUpload = (file) => {
    const isSupportedFileType = fileTypes.includes(file.type);

    if (!isSupportedFileType) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t(
                'recommended_utility_message_form_failure_file_type'
              )}
            </Space>,
          ],
        },
      ]);

      setUploadedFile([]);
      return Upload.LIST_IGNORE;
    }

    setUploadedFile([file]);
    setIsBeforeUpload(true);
    return false;
  };

  const handlePayload = (values, existingPayload = {}) => {
    let payload = { ...existingPayload };
    for (const input of Object.keys(values)) {
      if (input in values) {
        if (input === 'doc-file') {
          continue;
        } else {
          payload[input] = values[input];
        }
      }
    }
    return payload;
  };

  const createRecommendedUtility = async (payload) => {
    // create
    create(partnershipId, payload, (success) => {
      if (success) {
        notification.success(getI18n().t('feedback_form_success'));
        clearForm();
        load(partnershipId, page, limit, order);
      } else {
        notification.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const updateRecommendedUtility = async (payload) => {
    update(partnershipId, recommendedUtilityId, payload, (success) => {
      if (success) {
        clearForm();
        notification.success(
          getI18n().t('care_guide_document_message_data_success')
        );
        load(partnershipId, page, limit, order);
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_update')
        );
      }
    });
  };

  const updateRecommendedUtilityWithDocument = async (payload) => {
    update(partnershipId, recommendedUtilityId, payload, (success) => {
      if (success) {
        if (
          data.file_name_bucket &&
          data.file_directory &&
          data.file_extension &&
          isBeforeUpload
        ) {
          const path = `${data.file_directory}/${data.file_name_bucket}.${data.file_extension}`;
          destroy(path, (successDestroy) => {
            if (!successDestroy) {
              notification.error(
                getI18n().t(
                  'recommended_utilities_message_form_failure_delete_aws'
                )
              );
            }
          });
        }
        notification.success(
          getI18n().t('recommended_utilities_updated_success')
        );
        clearForm();
      } else {
        notification.error(
          getI18n().t('recommended_utilities_updated_failure')
        );
      }
    });
  };

  const handleFileUpload = async (values) => {
    const handleUploadSuccess = (uploadSuccess, file_id, bucket) => {
      if (uploadSuccess) {
        const directory = 'recommended-utilities-documents/';
        if (file_id) {
          file_id = file_id.replace(directory, '');

          const matches = reFileKey.exec(file_id);
          const payload = {
            file_directory: directory.slice(0, -1),
            file_name_bucket: matches[2],
            file_extension: matches[3],
            file_bucket: bucket,
            size_bytes: values['doc-file'].size,
            file_name: values['doc-file'].name,
          };
          const updatedPayload = handlePayload(values, payload);
          if (recommendedUtilityId) {
            updateRecommendedUtilityWithDocument(updatedPayload);
          } else {
            createRecommendedUtility(updatedPayload);
          }
        }
      } else {
        notification.error(
          getI18n().t(
            'recommended_utilities_message_form_failure_uploading_aws'
          )
        );
      }
    };

    props.getUploadUrl(
      values['doc-file'].type.split('/').pop(),
      'recommended-utilities-documents',
      async (success, upload_url, file_id, bucket) => {
        if (success) {
          props.upload(upload_url, values['doc-file'], (uploadSuccess) =>
            handleUploadSuccess(uploadSuccess, file_id, bucket)
          );
        } else {
          notification.error(getI18n().t('feedback_form_error'));
        }
      }
    );
  };

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (recommendedUtilityId && !isSubmitting) {
      const dataObj = JSON.parse(dataString);

      if (
        dataObj['file_name_bucket'] &&
        dataObj['file_directory'] &&
        dataObj['file_extension']
      ) {
        setOptionType(2);
        form.setFieldsValue({
          'doc-file': 'preloaded',
        });

        const defaultFile = [
          {
            uid: data['file_name_bucket'],
            name: `${data['file_name']}`,
            status: 'done',
          },
        ];
        setUploadedFile(defaultFile);
        setIsBeforeUpload(true);
      }
      form.setFieldsValue(dataObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, recommendedUtilityId]);

  const submitData = async (values) => {
    Logger.log(
      'debug',
      `DashboardAdminRecommendedUtilityFormModal.submitData(###)`
    );

    if ('doc-file' in values) {
      if (values['doc-file'] === 'preloaded') {
        const payload = handlePayload(values, data);
        updateRecommendedUtility(payload);
      } else {
        handleFileUpload(values);
      }
    } else {
      // API POST/PUT payload
      let payload = {};
      for (const input of Object.keys(values)) {
        if (input in values) {
          payload[input] = values[input];
        }
      }

      if (recommendedUtilityId) {
        updateRecommendedUtilityWithDocument(payload);
      } else {
        createRecommendedUtility(payload);
      }
    }
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'removed') {
      setUploadedFile([]);
      form.setFieldsValue({
        'doc-file': null,
      });
    } else {
      setUploadedFile([info.file]);
      form.setFieldsValue({
        'doc-file': info.file,
      });
    }
  };

  const clearForm = () => {
    props.hideForm();
    props.formDestroy();
    setIsBeforeUpload(false);
    setUploadedFile([]);
    setOptionType(1);
    form.setFieldsValue({
      title: '',
      'doc-file': null,
      website: '',
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log(
      'debug',
      `DashboardAdminRecommendedUtilityFormModal.handleFinish(###)`
    );
    if (!isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log(
      'debug',
      `DashboardAdminRecommendedUtilityFormModal.handleFinishFailed(###)`
    );
    notification.error(getI18n().t('recommended_utility_message_form_failure'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error notification when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  const uploadIcon = () => {
    return isBeforeUpload ? documentCheck() : <FileOutlined />;
  };

  const onChangeOptionType = (value) => {
    setOptionType(value);
    form.setFieldsValue({
      'doc-file': null,
      website: '',
    });
    setUploadedFile([]);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Row justify='space-between'>
                <Col>
                  <Title level={3} className='doc-title'>
                    {recommendedUtilityId
                      ? t('recommended_utilities_form_modal_header_title_edit')
                      : t('recommended_utilities_form_modal_header_title_new')}
                  </Title>
                </Col>
              </Row>
            }
            width={700}
            closable={true}
            className='recommended-utility-modal'
            centered
            visible={visible}
            onOk={onClickOK}
            onCancel={clearForm}
            footer={[
              <Button key='back' onClick={clearForm}>
                {t('recommended_utilities_form_modal_cancel')}
              </Button>,
              <Button
                key='submit'
                type='primary'
                className='btn-save'
                loading={isSubmitting}
                onClick={onClickOK}
              >
                {recommendedUtilityId
                  ? t('recommended_utilities_form_modal_edit_save')
                  : t('recommended_utilities_form_modal_add_save')}
              </Button>,
            ]}
          >
            <Form
              form={form}
              name='recommended_utility_form'
              {...layout.main}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger='onSubmit'
              className='form-doc-modal'
            >
              <Form.Item
                name='title'
                label={t('recommended_utilities_form_modal_title')}
                rules={[
                  {
                    required: true,
                    message: t('feedback_validation_required'),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'recommended_utilities_form_modal_title_placeholder'
                  )}
                />
              </Form.Item>
              <Text type='secondary'>
                {t('recommended_utilities_form_modal_information_description')}
              </Text>

              <div className='form-group'>
                <Form.Item>
                  <Radio.Group
                    size={'large'}
                    onChange={(e) => onChangeOptionType(e.target.value)}
                    value={optionType}
                  >
                    <Space direction='vertical'>
                      <Radio value={1}>
                        {t('recommended_utilities_website')}
                      </Radio>
                      <Radio value={2}>
                        {t('recommended_utilities_document')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              {optionType === 1 && (
                <div className='form-group'>
                  <Form.Item
                    name='website'
                    label={t('recommended_utilities_form_modal_website')}
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                  >
                    <Input disabled={isSubmitting} />
                  </Form.Item>
                </div>
              )}
              {optionType === 2 && (
                <>
                  <Text type='secondary' style={{ fontSize: '14px' }}>
                    {t('recommended_utilities_form_modal_information_document')}
                  </Text>
                  <Form.Item
                    name='doc-file'
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                    ]}
                  >
                    <Upload
                      className={isBeforeUpload ? 'doc-upload' : ''}
                      iconRender={uploadIcon}
                      onChange={(info) => handleFileChange(info)}
                      fileList={uploadedFile}
                      listType={'picture'}
                      beforeUpload={onBeforeUpload}
                      multiple={false}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t('recommended_utilities_form_modal_upload_button')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </>
              )}
            </Form>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default DashboardAdminRecommendedUtilityFormModal;
